import defaultCss from "./default.module.css";
import {useSelector} from "react-redux";
import CommonHeader from "./header";
import VerticalSpace from "../../components/common/vertical-space";

const DefaultLayout = ({children}) => {
    const IS_SUB_PROJECT = useSelector((state) => state.hostData.IS_SUB_PROJECT || false);

    return <div className={`${defaultCss.einLayout}${IS_SUB_PROJECT ? '' : ` ${defaultCss.einLayoutInMainProject}`}`}>
        <VerticalSpace>
            <CommonHeader/>
            {children}
        </VerticalSpace>
    </div>
}

export default DefaultLayout