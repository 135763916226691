import {store} from "../store";
import axios from "axios";
import {Modal} from "antd";
import {tryCatch} from "./utils";

const Axios = axios.create({
    headers: {
        'Accept': 'application/json',
        'x-client-type':'web',
        'x-app-id': 4,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
    }
})

//request拦截器
Axios.interceptors.request.use((config) => {
    const state = store.getState();

    config.baseURL = state.hostData.API_HOST
    config.headers['x-token'] = state.userData.token
    config.headers['search-cache-type'] = `<ein>${window.location.pathname}${window.location.search}`

    return config
})


//response拦截器 对于公共code统一处理
Axios.interceptors.response.use((resData) => {
    const data = resData.data;
    const msg = `${data.message || '服务器异常'}`;

    if (resData.config.responseType === "arraybuffer") {
        return resData
    } else if (Number(data.code) === 0) {
        return data.data
    } else if(data.code === 'PH10314') {
        //仅用于拦截/v4/account/login/by-ein的错误
        const state = store.getState();
        tryCatch(() => localStorage.removeItem(state.hostData.lsKey), () => void 0)
        //没有登录的话，则到403页面，要求用户通过刷新页面来重新获取token
        window.location.replace(`${state.hostData.basename}/403?msg=${msg}`)
    } else if(data.code === 'NH80000') {
        const state = store.getState();
        tryCatch(() => localStorage.removeItem(state.hostData.lsKey), () => void 0)
        //没有登录的话，则到403页面，要求用户通过刷新页面来重新获取token
        window.location.replace(`${state.hostData.basename}/403`)

        return new Promise(() => void 0)
    }else {
        //报错
        Modal.error({
            title: <pre>{msg}</pre>,
            okText: '确认'
        });

        return Promise.reject(msg)
    }

}, (err) => {
    if (axios.isCancel(err)) {
        return Promise.reject(err)
    } else {
        let msg = `${err?.response?.data?.message || err.message || '服务器异常'}`

        Modal.error({
            title: <pre>{msg}</pre>,
            okText: '确认'
        });

        return Promise.reject(msg)
    }
})

export default Axios