import "./public-path"
import React from 'react';
import {createRoot} from 'react-dom/client';
import moment from 'moment';
import {ConfigProvider} from "antd";
import zn from "antd/es/locale/zh_CN"
import 'moment/locale/zh-cn';
import {changeConfirmLocale} from "antd/lib/modal/locale";
import './assets/css/global.css';

import MiddlewareRedux from "./middleware/redux";
import ModalComponents from "./middleware/modal-components";
import {RouteHocComponent} from "./router";
import {getUrlQueryObject, tryCatch} from "./plugins/utils";

moment.locale('zh-cn');

changeConfirmLocale(zn.Modal)

const theme = {
    token: {
        colorPrimary: '#229ac3'
    }
}

const lsKeyDefault = 'hse_s_key';

function App({ token, base, lsKey = lsKeyDefault }) {
    return (
        <ConfigProvider theme={theme} locale={zn} dropdownMatchSelectWidth={false} input={{autoComplete: 'off'}}>
            <MiddlewareRedux basename={base} einToken={token} lsKey={lsKey} isSubProject={Boolean(window.__POWERED_BY_QIANKUN__)}>
                <ModalComponents>
                    <RouteHocComponent/>
                </ModalComponents>
            </MiddlewareRedux>
        </ConfigProvider>
    )
}

let root

function render(props) {
    const { container } = props;

    root = createRoot(container ? container.querySelector('#HSE_MANAGE') : document.querySelector('#HSE_MANAGE'));
    root.render(<App token={props.token} base={props.base} lsKey={props.lsKey}/>)
}

//非微应用
if (!window.__POWERED_BY_QIANKUN__) {
    let ls = tryCatch(() => (localStorage.getItem(lsKeyDefault) || '').split(','), () => ['', ''])
    document.querySelector('body').style.margin = '0px'

    //可测试用的token 646470F4F32A6
    render({
        token: getUrlQueryObject().token || ls[0] || '',
        base: '',
    });
}

export async function bootstrap(props) {
    console.log(`${props.name} [bootstrap]`);
}

export async function mount(props) {
    console.log(`${props.name} [mount]`);
    render(props);
}

export async function unmount(props) {
    console.log(`${props.name} [unmount]`);
    if(root) {
        root.unmount()
        root = null
    }
}