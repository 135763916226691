import {Spin} from "antd";
import React from "react";

const loadingStyle = {
    padding: '30px 50px',
    textAlign: 'center'
}

const LazyLoading = () => {
    return <div style={loadingStyle}>
        <Spin size="large"/>
    </div>
}

export default LazyLoading