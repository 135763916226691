import axios from "../plugins/axios"
import {store} from "../store";

//获取session
function getSession() {
    return axios.get('/v3/account/session/info')
}

//登录
function loginByEin(einToken) {
    return axios.post('/v4/account/login/by-ein', {
        token: einToken
    })
}

export function getSessionByInit() {
    const state = store.getState();

    if(state.userData.token) {
        //有旧token，则直接获取session
        return new Promise((resolve, reject) => {
            getSession().then(res => {
                //登录，不然就需要重新post login
                if(res.token_info.utk_login === 1) {
                    resolve(res)
                } else {
                    //避免loginByEin node层 重复post session
                    store.dispatch({ type: 'SET_TOKEN', payload: res.token_info.utk })

                    loginByEin(state.userData.einToken).then(res => {
                        resolve(res)
                    }).catch((err) => {
                        reject(err)
                    })
                }
            }).catch((err) => {
                reject(err)
            })
        })
    } else {
        //没有安全家token，则需要post login
        return loginByEin(state.userData.einToken)
    }
}