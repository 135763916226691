import React from "react";
import {createBrowserRouter, Outlet, RouterProvider, ScrollRestoration} from "react-router-dom";
import Session from "./middleware/session";
import {useSelector} from "react-redux";
import DefaultLayout from "./layouts/default/default";
import LazyLoading from "./components/common/lazy-loading";

//React.lazyLoad封装方法
function lazyLoad(Func, hasLoading = true) {
    const Comp = React.lazy(Func)

    return (
        <React.Suspense fallback={hasLoading ? <LazyLoading/> : void 0}>
            <Comp/>
        </React.Suspense>
    );
}

export const RouteHocComponent = React.memo(() => {
    const basename = useSelector((state) => state.hostData.basename || '');
    const router = React.useMemo(() => {
        return createBrowserRouter([{
            element: <>
                <Outlet/>
                <ScrollRestoration/>
            </>,
            children: [{
                element: <Session>
                    <DefaultLayout>
                        <Outlet/>
                    </DefaultLayout>
                </Session>,
                children: [
                    {
                        path: '/',
                        element: lazyLoad(() => import('./pages/home/home'))
                    },
                    {
                        path: '/learn-sort/cpy',
                        element: lazyLoad(() => import('./pages/learn-sort/cpy'))
                    },
                    {
                        path: '/learn-sort/user',
                        element: lazyLoad(() => import('./pages/learn-sort/user'))
                    },
                    {
                        path: '/plan-group-info',
                        element: lazyLoad(() => import('./pages/plan-group-info/plan-group-info'))
                    },
                    {
                        path: '/plan-group-user-info',
                        element: lazyLoad(() => import('./pages/plan-group-user-info/plan-group-user-info'))
                    },
                    {
                        path: '/study-plan-member-info',
                        element: lazyLoad(() => import('./pages/study-plan-member-info/study-plan-member-info'))
                    },
                    {
                        path: '/study-record-info',
                        element: lazyLoad(() => import('./pages/study-record-info/study-record-info'))
                    },
                    {
                        path: '/answer-info',
                        element: lazyLoad(() => import('./pages/answer-info/answer-info'))
                    },
                    {
                        path: '/download-task-manage',
                        element: lazyLoad(() => import('./pages/download-task-manage/download-task-manage'))
                    },
                    // 保留旧页面支持
                    {
                        path: '/down-task-manage',
                        element: lazyLoad(() => import('./pages/download-task-manage/download-task-manage'))
                    }
                ]
            }, {
                path: '*',
                element: lazyLoad(() => import('./pages/404'), false)
            }, {
                path: '500',
                element: lazyLoad(() => import('./pages/500'), false)
            }, {
                path: '403',
                element: lazyLoad(() => import('./pages/403'), false)
            }]
        }], { basename })
    }, [basename])

    return <RouterProvider router={router}/>
})