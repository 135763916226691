import React, {useState} from "react";
import {useMount} from "ahooks";

//初始化ModalComponents数组
window.HSE_EIN_MODAL_COMS = []

//用于处理弹框的在ConfigProvider和RouterProvider里
//配合appendReactComponent使用
const ModalComponents = ({children}) => {
    const [com, setCom] = useState(window.HSE_EIN_MODAL_COMS)

    //初始化
    useMount(() => {
        //删除组件
        window.delHseEinModalCom = (id) => {
            let item = window.HSE_EIN_MODAL_COMS.findIndex(e => e.id === id)
            if(item !== -1) {
                window.HSE_EIN_MODAL_COMS.splice(item, 1)
                window.HSE_EIN_MODAL_COMS = [...window.HSE_EIN_MODAL_COMS]
            }

            setCom(window.HSE_EIN_MODAL_COMS)
        }

        //添加组件
        window.addHseEinModalCom = (item) => {
            window.HSE_EIN_MODAL_COMS = [...window.HSE_EIN_MODAL_COMS, item]

            setCom(window.HSE_EIN_MODAL_COMS)
        }
    })

    return <>
        {children}
        {com.map(e => <div key={e.id}>{e.reactNode}</div>)}
    </>
}

export default ModalComponents