import homeCss from "../../pages/home/home.module.css";
import logo from "../../assets/img/logo.png";
import {PageHeader} from "@ant-design/pro-layout";
import {useSelector} from "react-redux";

const CommonHeader = () => {
    const company_name = useSelector((state) => state.userData.session.company_info.company_name)

    return <PageHeader
        ghost={false}
        title={<div className={homeCss.title}>
            <img className={homeCss.logo} src={logo} alt=""/>
            <span>当前企业客户：{company_name}</span>
        </div>}
    />
}

export default CommonHeader