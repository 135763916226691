import {useEffect, useState} from "react";
import {Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getSessionByInit} from "../api/account";
import {tryCatch} from "../plugins/utils";

const loadingStyle = {
    padding: '30px 50px',
    textAlign: 'center'
}

const Session = ({children}) => {
    const [loading, setLoading] = useState(true)
    const einToken = useSelector((state) => state.userData.einToken || '');
    const lsKey = useSelector((state) => state.hostData.lsKey);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    //在这里发起请求
    useEffect(() => {
        if(einToken) {
            getSessionByInit().then(res => {
                if(res.company_info) {
                    tryCatch(() => {
                        localStorage.setItem(lsKey, `${einToken},${res.token_info.utk}`)
                    }, () => void 0)
                    dispatch({ type: 'SET_TOKEN', payload: res.token_info.utk })
                    dispatch({ type: 'SET_SESSION', payload: res })
                    setLoading(false)
                }else {
                    //极端情况下没有返回company_info，比如员工被踢出企业
                    tryCatch(() => localStorage.removeItem(lsKey), () => void 0)
                    navigate('/403', { replace: true })
                }
            }).catch((msg) => {
                navigate(`/500?msg=${msg}`, { replace: true })
            })
        } else {
            //未空的话，则清空lsKey，并跳转到403页面
            tryCatch(() => localStorage.removeItem(lsKey), () => void 0)
            navigate('/403', { replace: true })
        }

    }, [einToken, dispatch, navigate, lsKey])

    if(loading) {
        return <div style={loadingStyle}>
            <Spin size="large"/>
        </div>
    } else {
        return children
    }
}

export default Session