//state
const initState = {
    einToken: '',
    token: '',
    session: {}
}

//更改state的唯一方法
export const userDataReducer = (state = initState, {type, payload}) => {
    let updateState = {}

    switch (type) {
        case 'SET_TOKEN' :
            updateState = {
                token: payload
            }

            break;
        case 'SET_EIN_TOKEN' :
            updateState = {
                einToken: payload
            }

            break;
        case 'SET_SESSION':
            updateState = {
                session: payload,
            }

            break
        default:
            return state
    }

    return {
        ...state,
        ...updateState
    }
}