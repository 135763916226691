import {Provider} from 'react-redux'
import initStore from "../store";
import {useEffect, useState} from "react";
import {tryCatch} from "../plugins/utils";

//redux中间件
const MiddlewareRedux = ({children, basename, einToken, isSubProject, lsKey}) => {
    const [store, setStore] = useState(null)

    useEffect(() => {
        const s = initStore()

        s.dispatch({ type: 'SET_BASENAME', payload: basename })
        s.dispatch({ type: 'SET_API_HOST', payload: $API_HOST })
        s.dispatch({ type: 'SET_CERT_HOST', payload: $CERT_HOST })
        s.dispatch({ type: 'SET_EIN_TOKEN', payload: einToken })
        s.dispatch({ type: 'SET_IS_SUB_PROJECT', payload: isSubProject })
        s.dispatch({ type: 'SET_LS_KEY', payload: lsKey })

        let ls = tryCatch(() => (localStorage.getItem(lsKey) || '').split(','), () => [])

        if(ls.length === 2 && ls[0] === einToken) {
            //einToken和旧的对得上的话，则使用旧的安全家token
            s.dispatch({ type: 'SET_TOKEN', payload: ls[1] })
        }

        setStore(s)
    }, [basename, einToken, isSubProject, lsKey])

    if(store) {
        return <Provider store={store}>
            {children}
        </Provider>
    } else {
        return <></>
    }
}

export default MiddlewareRedux