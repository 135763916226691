import moment from "moment";


/**
 *时间转为时间戳
 *@param {number | string} value 时间(new date())
 *@param {'end' | 'start'} type 时间戳类型  'end' 当天的23:59:59 'start' 当天的0:0:0
 *@return {number} 时间戳
 **/
export function T2Timestamp(value, type) {
    let num = Number(value)
    if (num.toString().length <= 10) {
        num = num * 1000
    }

    let date = new Date(num)

    if (type === 'end') {
        date.setHours(23, 59, 59)
    } else if (type === 'start') {
        date.setHours(0, 0, 0)
    }

    return Math.floor(Number(date) / 1000)
}

//
/**
 * 时间戳转为时间对象
 *@param {number} timestamp 时间戳
 *@return {moment.Moment} moment对象
 **/
export function T2MomentTime(timestamp) {
    if (!timestamp) {
        return void 0
    }
    if (timestamp.toString().length <= 10) {
        timestamp = timestamp * 1000
    }

    return moment(new Date(Number(timestamp)))
}

/**
 * 时间转化
 *@param {number} value 时间戳
 *@param {'yy-MM-dd-hh-mm' | 'cn MM-dd' | 'cn YY' | 'cn YY-MM' | 'cn YY-MM-dd' | 'cn YY-MM-dd-hh-mm' | 'YY-MM-dd'} type 时间转化类型
 *@return {string} 时间展示字段
 **/
export function t2Full(value, type) {
    if (value === 9999999999) {
        return '至今'
    }

    if (value === 0 || !value) {
        return '-'
    }

    let date = new Date();
    date.setTime(value * 1000);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    let m = date.getMinutes();
    m = m < 10 ? ('0' + m) : m;
    let s = date.getSeconds();
    s = s < 10 ? ('0' + s) : s;

    switch (type) {
        case 'cn YY':
            return Number(y) + '年'
        case 'cn YY-MM':
            return Number(y) + '年' + Number(MM) + '月'
        case 'cn YY-MM-dd':
            return Number(y) + '年' + Number(MM) + '月' + Number(d) + '日'
        case 'cn YY-MM-dd-hh-mm':
            return Number(y) + '年' + Number(MM) + '月' + Number(d) + '日' + h + '点' + m + '分';
        case 'yy-MM-dd-hh-mm':
            return y + '-' + MM + '-' + d + ' ' + h + ':' + m;
        case 'cn MM-dd':
            return Number(MM) + '月' + Number(d) + '号'
        case 'YY-MM-dd':
            return y + '-' + MM + '-' + d
        default:
            return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    }
}

/**
 * 字母序号(0~9)
 * @param num - 数字
 */
export function T2English(num) {
    let engArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']

    return engArr[Number(num)]
}

//文件大小 b转化为 kb M
export function t2FileSize(value) {
    let v = (value / 1024).toFixed(2);

    if (v > 1024) {
        let size = (v / 1024).toFixed(2);
        return `${size}M`
    } else {
        return `${v}kb`
    }
}

/**
 * 获取url中的query对象
 *@return {any} query对象
 **/
export function getUrlQueryObject() {
    let search = window.location.search.slice(1)
    let obj = {}

    search.split('&').forEach(item => {
        const execRes = /([^&=]+)=?([^&]*)/.exec(item)

        if (execRes) {
            obj[execRes[1]] = execRes[2]
        }
    })

    return obj
}

let id = 0

/**
 * 插入react节点
 *@param {React.ReactNode} Com React.ReactNode
 *@param {any} props 组件传参
 **/
export function appendReactComponent(Com, props) {
    let componentId = id++

    let destroyFunc = () => {
        window.delHseEinModalCom(componentId)
    }

    window.addHseEinModalCom({
        id: componentId,
        reactNode: <>
            <Com {...props} destroyFunc={destroyFunc}/>
        </>
    })
}

/**
 * try catch
 *@param {Function} tryFunc
 *@param {Function} catchFunc
 *@return {any} 返回参数
 **/
export function tryCatch(tryFunc, catchFunc) {
    try {
        return tryFunc()
    } catch (e) {
        return catchFunc(e)
    }
}