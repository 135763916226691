//state
const initState = {
    basename: '',
    lsKey: '',
    API_HOST: '',
    CERT_HOST: '',
    IS_SUB_PROJECT: false
}

//更改state的唯一方法
export const hostDataReducer = (state = initState, {type, payload}) => {
    let updateState = {}

    switch (type) {
        case 'SET_BASENAME' :
            updateState = {
                basename: payload
            }

            break;
        case 'SET_API_HOST' :
            updateState = {
                API_HOST: payload
            }

            break;
        case 'SET_CERT_HOST' :
            updateState = {
                CERT_HOST: payload
            }

            break;
        case 'SET_IS_SUB_PROJECT' :
            updateState = {
                IS_SUB_PROJECT: payload
            }

            break;
        case 'SET_LS_KEY':
            updateState = {
                lsKey: payload
            }

            break;
        default:
            return state
    }

    return {
        ...state,
        ...updateState
    }
}